<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"/>
    <TextTemplateForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
      :loading="isLoading"
      v-if="item"
    />
    <Loading :visible="isLoading || deleteLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import TextTemplateForm from '../../components/textTemplate/Form.vue';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import UpdateMixin from '../../mixins/UpdateMixin';

const servicePrefix = 'TextTemplate';

export default {
  name: 'TextTemplateUpdate',
  servicePrefix,
  mixins: [UpdateMixin],
  data() {
    return {
      updateMessage: this.$t('textTemplateUpdateSuccess'),
      updateRoute: null
    };
  },
  components: {
    Loading,
    Toolbar,
    TextTemplateForm
  },
  computed: {
    ...mapFields('textTemplate', {
      deleteLoading: 'isLoading',
      isLoading: 'isLoading',
      error: 'error',
      deleted: 'deleted',
      updated: 'updated',
      violations: 'violations'
    }),
    ...mapGetters('textTemplate', ['find'])
  },
  methods: {
    ...mapActions('textTemplate', {
      deleteItem: 'del',
      retrieve: 'load',
      update: 'update',
    }),
  },
}
</script>
